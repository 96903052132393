@font-face {
  font-family: "Futura PT";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/FuturaPTMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/FuturaPTBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/OpenSansRegular.ttf") format("truetype");
}
